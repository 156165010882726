import React from "react"
import Layout from "../../components/layout"
import "../../styles/branches.css"
import { getWords } from "../../helper-functions"
import SEO from "../../components/seo"
import { wheretostay } from "../../data/parents'-section"
import HostelCard from "../../components/hostelCard"
function WhereToStay() {
  return (
    <div>
      <Layout>
        <SEO
          title="WhereToStay"
          description={`IIT Roorkee strongly believes in the old Indian adage "Athithi Devo Bhava". We give our visitors the best facilities be it in terms of
          service, ambience or food. The campus has three guest houses to
          accommodate the guests and delegates who visit the campus.`}
        />
        <h2 className="dark-color-head">Where To Stay</h2>
        <p className="normal-para">
          IIT Roorkee strongly believes in the old Indian adage "Athithi Devo
          Bhava". We give our visitors the best facilities be it in terms of
          service, ambience or food. The campus has three guest houses to
          accommodate the guests and delegates who visit the campus.
        </p>
        <div className="branches-card">
          {wheretostay.map(guest => (
            <div>
              <a href={guest.site} target="_blank" rel="noopener noreferrer">
                <HostelCard
                  carddata={{
                    title: guest.name,
                    content: `${getWords(guest.content, 13)}...`,
                    link: guest.site,
                    image: guest.image,
                  }}
                />
              </a>
            </div>
          ))}
        </div>
      </Layout>
    </div>
  )
}

export default WhereToStay
